<template lang="pug">
  b-card.organization-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('organization.menus.my_organization') }}
    b-form.form-wc.form-organization-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-organization-identity_attributes-username(
              :label="$t('activerecord.attributes.identity.username')"
              label-for='organization-identity_attributes-username'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#organization-identity_attributes-username.form-input-first.shadow-sm.is-required(
                name='identity.username'
                type='text'
                autocomplete='username'
                :placeholder="$t('identity.placeholders.username')"
                :state='formValidationState($v.form.data.attributes.identity_attributes.username)'
                @focus.native='formChange'
                @input='$v.form.data.attributes.identity_attributes.username.$touch'
                v-model='identityAttributes.username'
                aria-describedby='organization-identity_attributes-username-feedback'
                trim)
              wc-forms-if#organization-identity_attributes-username-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.identity_attributes.username'
                :remote="formRemoteInvalidFeedback('identity.username')"
                :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {}, takenOrNotAllowed: {} }')

            b-form-group#form-organization-name(
              :label="$t('activerecord.attributes.shared.name')"
              label-for='organization-name'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#organization-name.form-input-last.shadow-sm.is-required(
                name='name'
                type='text'
                autocomplete='name'
                :placeholder="$t('organization.placeholders.name')"
                :state='formValidationState($v.form.data.attributes.name)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.name.$model'
                aria-describedby='organization-name-feedback'
                trim)
              wc-forms-if#organization-name-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.name'
                :remote="formRemoteInvalidFeedback('name')"
                :validators='{ required: {}, minLength: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-organization-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiOrganization from '@services/api/organization'
import { Form, mapNested } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'organization-form',
  mixins: [Form],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapNested([
      {
        name: 'identityAttributes',
        default: { data: 'relationships.identity.data', key: 'id', type: 'identity' },
        attributes: 'attributes.identity_attributes',
      },
    ]),
    apiParams() {
      return {
        get: [],
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        uniqueIdentityUsername: [
          {},
          { term: this.identityAttributes.username },
          { responseErrorRaw: true },
        ],
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            identity_attributes: {
              username: {
                required,
                regExp: regExp(this.$wc.conf.regex.username_browser),
                minLength: minLength(this.$wc.conf.limits.min_username),
                maxLength: maxLength(this.$wc.conf.limits.max_username),
                takenOrNotAllowed: async () => {
                  /* Touch attribute first. */
                  this.identityAttributes.username

                  /* Validate only if. */
                  if (
                    this.validateIf(
                      'identity_attributes.username',
                      ['required', 'regExp', 'minLength', 'maxLength'],
                      { if: 'invalid' }
                    )
                  )
                    return true

                  /* Validate remote. */
                  return await new Promise((resolve) => {
                    clearTimeout(this.form.timer.uniqueIdentityUsername)
                    this.form.timer.uniqueIdentityUsername = setTimeout(resolve, 600)
                  }).then(() => {
                    if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
                    this.apiRequest = this.formRequest('uniqueIdentity', 'uniqueIdentityUsername')
                    return this.apiRequest.promise // eslint-disable-line promise/no-nesting
                      .then(() => {
                        return true
                      })
                      .catch((error) => {
                        return error.response && error.response.status === 422 ? false : true
                      })
                  })
                },
                remote: () => this.formRemoteValid('identity.username'),
              },
            },
            name: {
              required,
              minLength: minLength(this.$wc.conf.limits.min_name),
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('name'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiOrganization,
      apiModel: 'organization',
    }
  },
}
</script>
